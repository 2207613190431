













import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import debounce from 'lodash.debounce'

@Component({
  name: 'Multiline'
})
export default class Multiline extends Vue {
  @Prop({ default: '' }) label!: string
  @Prop({ default: '' }) label2!: string
  @Prop({ default: '' }) value!: string
  @Prop({ default: 10 }) row!: number
  @Prop({ default: '' }) placeholder!: string

  private debounceTimeout = 500
  private current = ''

  constructor() {
    super()
    this.fireChange = debounce(
      this.fireChange.bind(this),
      this.debounceTimeout
    ) as { (v: string): string }
    this.fireInput = debounce(
      this.fireInput.bind(this),
      this.debounceTimeout
    ) as { (v: string): string }
  }

  mounted() {
    this.current = this.value
  }

  get model(): string {
    return this.current
  }

  set model(model: string) {
    this.current = model
    this.fireInput(model)
  }

  onChange() {
    this.fireChange(this.current)
  }

  @Emit('change')
  fireChange(value: string) {
    return value
  }

  @Emit('input')
  fireInput(value: string) {
    return value
  }
}

