





















import { FactDescription } from '@/interfaces/engine-exports'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import { Component, Vue, Prop } from 'vue-property-decorator'
import vSelect from 'vue-select'

@Component({
  components: {
    vSelect
  }
})
export default class ListRenderer extends Vue {
  @Prop()
  fact!: FactDescription

  onSelected(selected: SelectOption) {
    const value = selected ? selected.value : ''
    this.$emit('fact-change', {
      id: this.fact.id,
      value: value
    } as FactChangeEvent)
  }

  get selected() {
    const value = this.fact.currentValue || this.fact.defaultValue
    return this.fact.options.find(o => o.value === value) || null
  }
}

export interface SelectOption {
  value: any;
  description: string;
}
