








import { Component, Prop, Vue } from 'vue-property-decorator'
import Multiline from '@/components/facts/Multiline.vue'
import FieldMixin from '@/components/facts/FieldMixin.vue'

@Component({
  components: {
    Multiline
  },
  mixins: [FieldMixin]
})
export default class MultilineRenderer extends Vue {
  @Prop() regex?: RegExp
}
